import React from "react";
import "../styles/App.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Button } from "../components/Button";
import CardItem from "../components/CardItem";
import { StaticImage } from "gatsby-plugin-image";

export default function About() {
  return (
    <>
      <NavBar
        title="About | Point Blank Moving"
        description="Point Blank Moving the best local moving service in KC. Kansas City's premier, five star moving company! Locally owned and operated we strive for great service, cheap prices, and reliable employees. Learn about who we are and what we stand for."
      />
      <div className="about-container">
        <h1 style={{ textShadow: "0 0 5px black, 0 0 5px black" }}>About</h1>
        <div className="landing-btns">
          <Button
            className="btns"
            buttonStyle="btn--primary"
            buttonSize="btn--large"
            path="/Quote"
          >
            Check Availability
          </Button>
          <Button
            className="btns"
            buttonStyle="btn--outline"
            buttonSize="btn--large"
            path="/Contact"
          >
            GET IN CONTACT
          </Button>
        </div>
      </div>

      <div>
        <div className="content">
          <br />
          <br />
          <h2
            style={{
              alignSelf: "center",
              fontSize: "40px",
            }}
          >
            Our Mission
          </h2>
          <div className="content-mission">
            To establish a five-star reputation in our community and to turn
            customers into lifelong clients. We want to give you the best
            stress-free and affordable moving experience KC can offer.
          </div>
          <div className="about-card">
            <CardItem
              text="Meet the owners Hayden and Mike Garcia."
              label="Local Business Owners"
            >
              <StaticImage
                className="cards__item__img"
                alt="Meet the owners Hayden and Mike Garcia."
                src="../images/owners.jpg"
                placeholder="blurred"
                loading="lazy"
                layout="constrained"
              />
            </CardItem>
          </div>
          <h3
            style={{
              paddingTop: "30px",
              alignSelf: "center",
              fontSize: "30px",
            }}
          >
            Who We Are
          </h3>
          <br />
          <div className="content-about">
            We are a Kansas City based company that is operated and managed
            locally. We strive to make a positive impact in our community by
            offering fast and reliable services personally suited for your
            needs. We always go the extra mile to make sure the job is done
            right even if that means finding creative ways to hit your budget!
          </div>
          <br />
          <br />
          <div className="content-about">
            Hayden and Mike are born and raised KC, they love this city and it's
            people. The father-son duo have been growing their business over the
            years through hard work and determination to contribute to the
            community.
          </div>
          <br />
          <br />
        </div>
      </div>
      <Footer />
    </>
  );
}
